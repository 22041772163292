import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/video-streaming-developer-docs/video-streaming-developer-docs/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import GQLCodeSnippet, { GQLOpen, GQLClose, GQLLine, GQLTab, GQLName, GQLKeyword, GQLAttribute, GQLOperator, GQLComment, GQLLink } from "../../components/GQLCodeSnippet/GQLCodeSnippet";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`The Address scalar type is either an IPv4 or FQDN address.
i.e: `}<inlineCode parentName="p">{`127.0.0.1`}</inlineCode>{`, `}<inlineCode parentName="p">{`www.google.com`}</inlineCode>{`, `}<inlineCode parentName="p">{`my.fqdn.com.`}</inlineCode>{`.`}</p>
    <GQLCodeSnippet mdxType="GQLCodeSnippet">
      <GQLLine mdxType="GQLLine"><GQLKeyword mdxType="GQLKeyword">scalar</GQLKeyword> ServerAddress</GQLLine>
    </GQLCodeSnippet>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      